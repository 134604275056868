import axios from "axios";

export const bitCheck = (t_id) => {
  let sum = 0;
  t_id.split("").forEach((char) => (sum += char.charCodeAt(0)));

  sum = 4 * sum + 12;

  return sum;
};
export const createSale = async (
  amount,
  t_id,
  source_id,
  coupon,
  lang,
  guideId = ""
) => {
  const MLP = "MPL16165-02603AGE-PMA09DDA-2B5HMIKT";
  const current = new Date();
  const activateCoupon = `${t_id.substring(5, 8)}${current.getMonth()}${
    (current.getDate() + current.getMonth() + current.getFullYear() + 7) % 5
  }`;
  console.log("activateCoupon", activateCoupon);
  const currencyCode = lang === "He" ? "ILS" : "USD";
  const sumBit = bitCheck(t_id);

  var body = {
    seller_payme_id: MLP,
    sale_price: Number(amount) * 100,
    currency: "ILS",
    product_name: "Tour",
    transaction_id: t_id.toString(), //"12345",
    installments: 1,
    sale_callback_url: `https://europe-central2-tours-app-1579553856346.cloudfunctions.net/callbackpaymeweb/?coupon=${coupon}&aCoupon=${activateCoupon}&locale=${lang}&tID=${t_id}&source=${source_id}&coupon=${coupon}&guideId=${guideId}`,
    //sale_return_url: `https://dguidetours.com/paymentReturn?tID=${t_id}&bitCheck=${sumBit}&source=${source_id}`,
    //sale_return_url: `https://dguidetours.com/returnPayment/?tID=${t_id}&aCoupon=${activateCoupon}&coupon=${coupon}&bitCheck=${sumBit}&source=${source_id}&lang=${lang}&guideId=${guideId}&totalA=${amount}`,
    sale_return_url: `https://dguidetours.com/thankYouPage/?tID=${t_id}&aCoupon=${activateCoupon}&bitCheck=${sumBit}&lang=${lang}`,
    capture_buyer: 0,
    language: lang,
  };

  console.log("BODY", body.sale_callback_url);
  //return axios.post("https://sandbox.payme.io/api/generate-sale", body);
  //return axios.post("https://preprod.paymeservice.com/api/generate-sale", body);
  return axios.post("https://ng.paymeservice.com/api/generate-sale", body);
};

export const proceedPayment = async (
  wantedTour,
  setUrl,
  sourceId = "dguide"
) => {
  let total_amount = wantedTour.price;

  let language = "En";
  if (wantedTour.language === "Hebrew") {
    language = "He";
  }
  /*  console.log(
    "creatSale",
    total_amount,
    wantedTour.id,
    sourceId,
    "NaN",
    language,
    wantedTour.tour_guide ? wantedTour.tour_guide.id : "NaN"
  ); */
  createSale(
    total_amount,
    wantedTour.id,
    sourceId,
    "NaN",
    language,
    wantedTour.tour_guide ? wantedTour.tour_guide.id : "NaN"
  )
    .then((res) => {
      //    console.log("res", res.data);
      setUrl(res.data.sale_url);
      //console.log("AFTER res", res.data.sale_url);
      return res.data.sale_url;
    })
    .catch((err) => {
      console.log("We have problem", err);
      //console.log("AFTER res", res.data.sale_url);
    });
};

export const paymePromise = function (
  wantedTour,
  sourceId = "dguide",
  coupon = "NaN"
) {
  return new Promise(function (resolve, reject) {
    let total_amount = wantedTour.price;
    /*  cart.cartItems.map((tour) => {
      total_amount += calcPrice(tour) * tour.count;
    }); 
  let t_id = total_amount;*/
    let language = "En";
    if (wantedTour.language === "Hebrew") {
      language = "He";
    }
    /* console.log("paymePayment Lang", language);
    console.log("guideID"); */
    createSale(
      total_amount,
      wantedTour.id,
      sourceId,
      coupon,
      language,
      wantedTour.tour_guide.id
    )
      .then((res) => {
        /* console.log("RES URL PROMISE", res.data.sale_url); */
        resolve(res.data.sale_url);
      })
      .catch((err) => console.log("Inside Promis", err) && reject(err));
    /*  console.log("AFTER CREATE SALE"); */
  });
};
